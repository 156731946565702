<template>
  <div
    v-for="text in allTexts"
    :key="text.date"
    v-bind:style="{
      top: text.top + 'px',
      left: text.left + 'px',
      fontSize: text.size + 'px',
      maxWidth: text.maxWidth + 'px',
    }"
    class="text-snippet"
  >
    <div v-if="text.type == 1">
      <mark class="link"
        ><a target="_blank" :href="text.link">{{ text.writtenText }}</a></mark
      >
    </div>
    <div v-else-if="text.type == 2">
      <mark class="diary">{{ text.writtenText }}</mark>
    </div>
    <div v-else>
      <mark class="trash">
        <i>{{ text.writtenText }}</i></mark
      >
    </div>
  </div>
</template>

<script>
import jsonData from "@/data.json";

export default {
  name: "App",
  data() {
    return {
      data: JSON.parse(JSON.stringify(jsonData)),
      width: window.innerWidth,
      height: window.innerHeight,
      typeInterval: 44.4,
      typeSizeMin: 14,
      typeSizeMax: 56,
      leftMin: 0,
      leftMax: window.innerWidth,
      characterIndex: 0,
      allTexts: [],
      currentText: {
        text: "",
        date: "",
        link: "",
      },
    };
  },
  components: {},
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
    console.log(
      "ₓ˚. ୭ ˚○◦˚.˚◦○˚ ୧ .˚ₓ˚. ୭ ˚○◦˚.˚◦○˚ ୧ .˚ₓ˚. ୭ ˚○◦˚.˚◦○˚ ୧ .˚ₓ https://www.youtube.com/watch?v=grmHUtacMkc ₓ˚. ୭ ˚○◦˚.˚◦○˚ ୧ .˚ₓ˚. ୭ ˚○◦˚.˚◦○˚ ୧ .˚ₓ˚. ୭ ˚○◦˚.˚◦○˚ ୧ .˚ₓ"
    );
    console.log("♥ thanks 4 reading ♥");
    // document.addEventListener("click", () => {
    //   clearInterval(refreshIntervalId);
    //   this.typeInterval = 44.4;
    //   setInterval(() => {
    //     this.type();
    //   }, this.typeInterval);
    // });

    this.setTypeSize();
    setInterval(() => {
      this.type();
    }, this.typeInterval);
  },
  methods: {
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    type() {
      this.currentText.writtenText += this.currentText.text.charAt(
        this.characterIndex
      );
      this.characterIndex++;

      if (this.characterIndex > this.currentText.text.length) {
        const newType = Math.floor(Math.random() * 3);
        if (
          this.data.trash.length <= 1 ||
          this.data.links.length <= 1 ||
          this.data.notes.length <= 1
        ) {
          this.resetData();
        }
        let newTypeOptions;
        switch (newType) {
          case 0:
            newTypeOptions = this.data.trash;
            break;
          case 1:
            newTypeOptions = this.data.links;
            break;
          case 2:
            newTypeOptions = this.data.notes;
            break;
        }

        const newIndex = Math.floor(Math.random() * newTypeOptions.length);
        this.currentText = JSON.parse(JSON.stringify(newTypeOptions[newIndex]));
        this.allTexts.push(this.currentText);
        newTypeOptions.splice(newIndex, 1);

        // Set generated styles
        this.currentText.size = this.randomIntFromInterval(
          this.typeSizeMin,
          this.typeSizeMax
        );
        this.currentText.maxWidth = this.randomIntFromInterval(
          this.currentText.size * 13,
          this.currentText.size * 22
        );

        this.currentText.left =
          this.randomIntFromInterval(
            this.currentText.maxWidth / 2,
            this.width - this.currentText.maxWidth / 2
          ) -
          this.currentText.maxWidth / 2;

        // Calculate height
        const testDiv = document.createElement("div");
        const testContent = document.createTextNode(this.currentText.text);
        testDiv.appendChild(testContent);
        testDiv.style.width = this.currentText.maxWidth + "px";
        testDiv.style.fontSize = this.currentText.size + "px";
        testDiv.style.visibility = "hidden";
        document.getElementById("app").appendChild(testDiv);
        
        this.currentText.top = this.randomIntFromInterval(
          0,
          this.height - testDiv.clientHeight
        );

        this.currentText.type = newType;
        this.currentText.writtenText = "";
        this.characterIndex = 0;
      }
    },
    onWindowResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    setTypeSize() {
      if (this.width >= 4000) {
        // 4K
        this.typeSizeMin = 48;
        this.typeSizeMax = 108;
      } else if (this.width > 2000) {
        // TV SIZE
        this.typeSizeMin = 24;
        this.typeSizeMax = 64;
      } else if (this.width > 820) {
        // DESKTOP
        this.typeSizeMin = 14;
        this.typeSizeMax = 48;
      } else {
        // MOBILE
        this.typeSizeMin = 12;
        this.typeSizeMax = 24;
      }
    },
    resetData() {
      this.allTexts = [];
      this.data = JSON.parse(JSON.stringify(jsonData));
    },
  },
};
</script>

<style>
html {
  overflow: hidden;
  cursor: none;
  height: 100vh;
  width: 100vw;
}

body {
  background: #c8b496;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  height: "100%";
  cursor: none;
}

::-moz-selection {
  /* Code for Firefox */
  color: #c8b496;
  background: #ffc8e3;
}

::selection {
  color: #c8b496;
  background: #ffc8e3;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  cursor: none;
}

a {
  color: #f11ea9;
  font-family: "Times New Roman", Times, serif;
  text-decoration: underline;
}

.hidden {
  display: none;
}

.underline {
  text-decoration: underline;
}

.italics {
  font-style: italic;
}

.text-snippet {
  position: fixed;
  text-align: center;
  display: inline-block;
}

mark {
  background-color: #c8b496;
}

mark.link {
  font-family: "Times New Roman", Times, serif;
  color: #f11ea9;
}

mark.diary {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
}

mark.trash {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffc8e3;
}
</style>
